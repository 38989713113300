import axios from 'axios'

import getURL, { getUCPURL }  from './env'

export const login = (username, password, realm) => {
  return axios.post(`${getUCPURL()}/api/v2/login`, {
    username,
    password,
    domain: realm
  })
}

export const currentUser = (token, user_id, account_id) => {
  return axios.get(`${getURL()}/v2/accounts/${account_id}/users/${user_id}`, {
    headers: {
      'X-Auth-Token': token
    }
  })
}

export function refreshToken (token) {
  return axios.post(`${getUCPURL()}/api/v2/refresh`, undefined, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  })
}

export const recovery = (username, realm) => {
  return axios.put(`${getURL()}/v2/user_auth/recovery`, {
    data: {
      username,
      ui_url: `${window.location.protocol}//${window.location.hostname}:${window.location.port}/${process.env.PUBLIC_URL}/reset`,
      account_name: realm
    }
  })
}

export const reset = (token) => {
  return axios.post(`${getURL()}/v2/user_auth/recovery`, {
    data: {
      reset_id: token
    }
  })
}

export const change_pass = (account_id, user_id, password, token) => {
  return axios.patch(`${getURL()}/v2/accounts/${account_id}/users/${user_id}`,
    {
      data: {
        password
      }
    },
    {headers: {
      'X-Auth-Token': token,
    }
    })
}

export const getRefreshToken = token => {
  return axios.get(`${getURL()}/onboard/v1/get_refresh_token`, {
    headers: { Authorization: `Bearer ${token}` }
  })
}
