export const SIPACCOUNT_CONNECTING = 'SIPACCOUNT_CONNECTING';
export const SIPACCOUNT_CONNECTED = 'SIPACCOUNT_CONNECTED';
export const SIPACCOUNT_DISCONNECTED = 'SIPACCOUNT_DISCONNECTED';
export const SIPACCOUNT_REGISTERED = 'SIPACCOUNT_REGISTERED';
export const SIPACCOUNT_UNREGISTERED = 'SIPACCOUNT_UNREGISTERED';
export const SIPACCOUNT_REGISTRATION_FAILED = 'SIPACCOUNT_REGISTRATION_FAILED';
// Message is currently not used for anything
export const SIPACCOUNT_MESSAGE = 'SIPACCOUNT_MESSAGE';

export function onConnected(id) {
  return {
    type: SIPACCOUNT_CONNECTED,
    payload: {
      id
    }
  };
}

export function onDisconnected(id) {
  return {
    type: SIPACCOUNT_DISCONNECTED,
    payload: {
      id
    }
  };
}

export function onRegistered(id) {
  return {
    type: SIPACCOUNT_REGISTERED,
    payload: {
      id
    }
  };
}

export function onUnregistered(id) {
  return {
    type: SIPACCOUNT_UNREGISTERED,
    payload: {
      id
    }
  };
}

export function onRegistrationFailed(id) {
  return {
    type: SIPACCOUNT_REGISTRATION_FAILED,
    payload: {
      id
    }
  };
}

export function onMessage(id) {
  return {
    type: SIPACCOUNT_MESSAGE,
    payload: {
      id
    }
  };
}

export function onConnecting(id) {
  return {
    type: SIPACCOUNT_CONNECTING,
    payload: {
      id
    }
  };
}
