import { patch } from "../webapi/callrecording"
import { getUserId, getAccountId, getUser } from "../stateapi/user"
import { getToken } from "../stateapi/auth"

export const CALL_RECORDING_STATE_CHANGE_REQUEST =
  "CALL_RECORDING_STATE_CHANGE_REQUEST"
export const CALL_RECORDING_STATE_CHANGE_RESPONSE =
  "CALL_RECORDING_STATE_CHANGE_RESPONSE"
export const CALL_RECORDING_STATE_CHANGE_ERROR =
  "CALL_RECORDING_STATE_CHANGE_ERROR"

const RECORDING_URL = "http://localhost:60080/record"

export const toggle = () => {
  return (dispatch, getState) => {
    dispatch({
      type: CALL_RECORDING_STATE_CHANGE_REQUEST
    })
    const user = getUser(getState())
    let data = {}
    if (user.hasCustomCallRec()) {
      data = {
        call_recording: {},
        smartpbx: { call_recording: { enabled: false } }
      }
    } else {
      data = {
        call_recording: {
          inbound: {
            offnet: {
              enabled: true,
              time_limit: 3600,
              url: RECORDING_URL,
              format: "mp3"
            }
          },
          outbound: {
            offnet: {
              enabled: true,
              time_limit: 3600,
              url: RECORDING_URL,
              format: "mp3"
            }
          }
        },
        smartpbx: { call_recording: { enabled: true } }
      }
    }
    patch(
      getAccountId(getState()),
      getUserId(getState()),
      data,
      getToken(getState())
    )
      .then(resp => {
        dispatch({
          type: CALL_RECORDING_STATE_CHANGE_RESPONSE,
          payload: resp.data.data.smartpbx.call_recording.enabled
        })
      })
      .catch(error => {
        dispatch({
          type: CALL_RECORDING_STATE_CHANGE_ERROR,
          error: true,
          payload: error
        })
      })
  }
}
