import {isMobile} from "react-device-detect";

export const NOTIFICATION_PERMISSION = {
  default: 'default',
  granted: 'granted',
  denied: 'denied'
}

export const MAX_ACTIVE_NOTIFICATIONS = 3
export const NOTIFICATION_CLOSE_TIMEOUT = 3000
// export const DEFAULT_ICON = logo

export class Notifier {
  constructor () {
    this.notifications = []
    this.notificationCloseTimeout = NOTIFICATION_CLOSE_TIMEOUT
    if(!isMobile) {
      if (Notification && Notification.permission === NOTIFICATION_PERMISSION.default) {
        Notification.requestPermission(function (permission) {
        })
      }
    }
  }

  addNotification (notification) {
    this.notifications.push(notification)
    if (this.notifications.length > MAX_ACTIVE_NOTIFICATIONS) {
      this.notifications[0].close()
      this.notifications.shift()
    }
  }

  /**
   * Display a browser notification with a title and options.
   *
   * @param {string} title - Text that the user will see
   * @param {Object} options - An optional options object
   * @param {String} options.body - Text fot the notification
   * @param {string} options.icon - Relative path to the icon that will be used for the notification
   * @param {function} options.onclick - A callback function that will be executed when the notification is clicked
   * @returns {Promise} - Resolves if successful, rejects if unsuccessful
   * @memberof Notifier
   */
    notify (title, options = {}) {
      return new Promise((resolve, reject) => {
        if (
          !Notification ||
          Notification.permission === NOTIFICATION_PERMISSION.denied
          ) {
          reject('Notifications are denied')
          }

  // Set default icon if no icon has been set
  // options.icon = options.icon || DEFAULT_ICON
  // options.webkitIcon = options.icon || DEFAULT_ICON
  options.requireInteraction = options.requireInteraction || true

  // Set timeout from the options
  this.notificationCloseTimeout = options.timeout || NOTIFICATION_CLOSE_TIMEOUT

  // Helper function to create a notification object, assign events and
  // return it
  const createNotification = (title, options) => {
    const notification = new Notification(title, options)
    notification.addEventListener('click', () => {
      notification && notification.close()
    })
    if (options.onclick && typeof options.onclick === 'function') {
      notification.addEventListener('click', options.onclick)
    }
    return notification
  }

        if (Notification.permission === NOTIFICATION_PERMISSION.default) {
          Notification.requestPermission()
            .then(result => {
              if (result === NOTIFICATION_PERMISSION.granted) {
                this.addNotification(createNotification(title, options))
              }
              resolve()
            })
            .catch(() => {
              reject()
            })
        } else if (Notification.permission === NOTIFICATION_PERMISSION.granted) {
          this.addNotification(createNotification(title, options))
          resolve()
        }
      })
    }
}

export default new Notifier()
