import DesktopConnectorClient from 'ucp/clients/desktop_connector/web_desktop_connector_client.jsx'

export function initialize(history) {
	DesktopConnectorClient.initialize(history)
}

export function call(extension) {
	DesktopConnectorClient.call(extension)
}

export function close() {
	DesktopConnectorClient.close()
}
