/**
 * @module actions/sipsessions
 *
 * Generates actions pertinent to all sessions (aka calls). Both inbound and
 * outbound sessions are fired here. Where they branch is in their respective
 * reducers.
 *
 */

import AccountManager from '../lib/AccountManager'
import ToneManager from '../lib/ToneManager'
import { callDisconnect as playSound } from '../lib/TonePlayer'
import { DEVICE_TYPES } from '../actions/audio'
import notifier from '../lib/Notifications'
import { getSessionById } from '../stateapi/sipsessions'
import { isWebRTCEnabledSelector } from '../stateapi/webrtc'
import { makeCall as quickcall } from './quickcall'
import { isAgentElectron } from 'ucp/stateapi/agent'
import { call } from 'ucp/actions/desktop_connector'

export const SIPSESSION_MUTE_TOGGLE_SUCCESS = 'SIPSESSION_MUTE_TOGGLE_SUCCESS'
export const SIPSESSION_MUTE_TOGGLE_FAIL = 'SIPSESSION_MUTE_TOGGLE_FAIL'
export const SIPSESSION_TRANSFER_REQUEST = 'SIPSESSION_TRANSFER_REQUEST'
export const SIPSESSION_TRANSFER_FAIL = 'SIPSESSION_TRANSFER_FAIL'
export const SIPSESSION_TRANSFER_SUCCESS = 'SIPSESSION_TRANSFER_SUCCESS'
export const SIPSESSION_CANCEL_TRANSFER = 'SIPSESSION_CANCEL_TRANSFER'
export const SIPSESSION_MARK_TRANSFER = 'SIPSESSION_MARK_TRANSFER'
export const SIPSESSION_ON_INVITE = 'SIPSESSION_ON_INVITE'
export const SIPSESSION_NEW = 'SIPSESSION_NEW'
export const SIPSESSION_PROGRESS = 'SIPSESSION_PROGRESS'
// There is no REJECT because it is the same as TERMINATED
export const SIPSESSION_REJECT_REQUEST = 'SIPSESSION_REJECT_REQUEST'
export const SIPSESSION_ACCEPT_REQUEST = 'SIPSESSION_ACCEPT_REQUEST'
export const SIPSESSION_ACCEPT = 'SIPSESSION_ACCEPT'
export const SIPSESSION_HOLD_REQUEST = 'SIPSESSION_HOLD_REQUEST'
export const SIPSESSION_HOLD_SUCCESS = 'SIPSESSION_HOLD_SUCCESS'
export const SIPSESSION_HOLD_FAIL = 'SIPSESSION_HOLD_FAIL'
export const SIPSESSION_HOLD_ALL_REQUEST = 'SIPSESSION_HOLD_ALL_REQUEST'
export const SIPSESSION_HOLD_ALL_SUCCESS = 'SIPSESSION_HOLD_ALL_SUCCESS'
export const SIPSESSION_HOLD_ALL_FAIL = 'SIPSESSION_HOLD_ALL_FAIL'
export const SIPSESSION_UNHOLD_REQUEST = 'SIPSESSION_UNHOLD_REQUEST'
export const SIPSESSION_UNHOLD_SUCCESS = 'SIPSESSION_UNHOLD_SUCCESS'
export const SIPSESSION_UNHOLD_FAIL = 'SIPSESSION_UNHOLD_FAIL'
export const SIPSESSION_CANCEL_REQUEST = 'SIPSESSION_CANCEL_REQUEST'
export const SIPSESSION_CANCEL_SUCCESS = 'SIPSESSION_CANCEL_SUCCESS'
export const SIPSESSION_CANCEL_FAIL = 'SIPSESSION_CANCEL_FAIL'
export const SIPSESSION_HANGUP_REQUEST = 'SIPSESSION_HANGUP_REQUEST'
export const SIPSESSION_HANGUP_SUCCESS = 'SIPSESSION_HANGUP_SUCCESS'
export const SIPSESSION_HANGUP_FAIL = 'SIPSESSION_HANGUP_FAIL'
export const SIPSESSION_DTMF_REQUEST = 'SIPSESSION_DTMF_REQUEST'
export const SIPSESSION_DTMF_SUCCESS = 'SIPSESSION_DTMF_SUCCESS'
export const SIPSESSION_DTMF_FAIL = 'SIPSESSION_DTMF_FAIL'
export const SIPSESSION_BYE = 'SIPSESSION_BYE'
export const SIPSESSION_FAILED = 'SIPSESSION_FAILED'
export const SIPSESSION_TERMINATED = 'SIPSESSION_TERMINATED'
export const SIPSESSION_MAKECALL_REQUEST = 'SIPSESSION_MAKECALL_REQUEST'
export const SIPSESSION_MAKECALL_SUCCESS = 'SIPSESSION_MAKECALL_SUCCESS'
export const SIPSESSION_MAKECALL_FAIL = 'SIPSESSION_MAKECALL_FAIL'
export const SIPSESSION_ICE_CONNECTION_OK = 'SIPSESSION_ICE_CONNECTION_OK'
export const SIPSESSION_ICE_CONNECTION_PROBLEM =
  'SIPSESSION_ICE_CONNECTION_PROBLEM'

export const onInvite = (account_id, session_id) => {
  console.error("onInvite");
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const sip = AccountManager.getSIP(account_id)
      const session = sip.getSession(session_id)
      const { config } = getState()
      sip
        .progressSession(session_id)
        .then(() => {
          if (config.isRingingActive) {
            console.error("ringing");
            ToneManager.ringtone(config.audio[DEVICE_TYPES.PHONE_RINGING])
          }
          dispatch({
            type: SIPSESSION_ON_INVITE,
            payload: {
              account_id,
              session_id,
              displayName: session.remoteIdentity.displayName,
              user: session.remoteIdentity.uri.user
            }
          })
          notifier.notify(
            `Incoming call from ${session.remoteIdentity.displayName}`,
            {
              onclick: () => {
                dispatch(doAnswer(account_id, session_id))
              }
            }
          )
          resolve()
        })
        .catch(error => {
          console.error('Failed to receive call because', error)
          reject(error)
        })
    })
  }
}

/**
 * Action called when a new call is outbound initiated
 *
 * @function
 * @param account_id {String} - The account ID associated with this session
 * @param session {String} - The session_id as generated
 *
 * @returns {Object} - The action that will be dispatched
 */
export const onSessionNew = (account_id, session_id) => {
  return {
    type: SIPSESSION_NEW,
    payload: {
      session_id,
      account_id
    }
  }
}

/**
 * Action related to a SIP BYE
 *
 * @function
 * @param account_id {String} - The account ID associated with this session
 * @param session {String} - The session_id as generated
 *
 * @returns {Object} - The action that will be dispatched
 */
export const onSessionBye = (account_id, session_id) => {
  return {
    type: SIPSESSION_BYE,
    payload: {
      account_id,
      session_id
    }
  }
}
/**
 * Action related to a sip failed event
 *
 * @param {String} account_id
 * @param {String} session_id
 *
 * @returns {Object} - The action that will be dispatched
 */
export const onSessionFailed = (account_id, session_id, interaction_id) => {
  return {
    type: SIPSESSION_FAILED,
    payload: {
      account_id,
      session_id,
      interaction_id
    }
  }
}

/**
 * Action related to when a call is answered (accepted on SIP jargon)
 *
 * When you call session.accept() on an incoming call, this event is fired.
 * On an outbound call, this gets fired when the remote party accepts
 *
 * @function
 * @param account_id {String} - The account ID associated with this session
 * @param session {String} - The session_id as generated
 *
 * @returns {Object} - The action that will be dispatched
 */
export const onSessionAccept = (account_id, session_id) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      let sip = AccountManager.getSIP(account_id)
      if (!sip) {
        console.error(
          `No SIP for account ${account_id} when
          session ${session_id} was answered.`
        )
        reject()
        return
      }
      let sipsession = sip.getSession(session_id)
      if (!sipsession) {
        console.error(
          `No session on  account ${account_id} when
          session ${session_id} was answered.`
        )
        reject()
        return
      }
      ToneManager.stopAll()
      dispatch({
        type: SIPSESSION_ACCEPT,
        payload: {
          account_id,
          session_id,
          startTime: sipsession.startTime
        }
      })
      resolve()
    })
  }
}

/**
 * Action related to when a call is terminated
 *
 * This is the last event on the session lifecycle
 *
 * @function
 * @param account_id {String} - The account ID associated with this session
 * @param session {String} - The session_id as generated
 *
 * @returns {Object} - The action that will be dispatched
 */
export const onSessionTerminated = (account_id, session_id) => (
  dispatch,
  getState
) => {
  const { config } = getState()
  ToneManager.stopAll()
  playSound(config.audio[DEVICE_TYPES.PHONE_OUTPUT])
  dispatch({
    type: SIPSESSION_TERMINATED,
    payload: {
      account_id,
      session_id
    }
  })
}

/**
 * Action related to when a call is progressing (ringing)
 *
 * @function
 * @param account_id {String} - The account ID associated with this session
 * @param session {String} - The session_id as generated
 *
 * @returns {Object} - The action that will be dispatched
 */
export const onSessionProgress = (account_id, session_id) => {
  return (dispatch, getState) => {
    const { config } = getState()
    ToneManager.ringtone(
      config.audio[DEVICE_TYPES.PHONE_RINGING]
    )
    dispatch({
      type: SIPSESSION_PROGRESS,
      payload: {
        account_id,
        session_id
      }
    })
  }
}

/**
 * Answers a session
 *
 * Most common use (if not the only) is incoming calls
 *
 * @function
 * @param account_id {String} - The account ID associated with this session
 * @param session {String} - The session_id as generated
 *
 * @returns {Object} - The action that will be dispatched
 */
export const doAnswer = (account_id, session_id) => {
  return (dispatch, getState) => {
    return dispatch(doHoldAll()).then(() => {
      const sip = AccountManager.getSIP(account_id)
      const { config } = getState()
      if (!sip) {
        console.error(`There is no account for id ${account_id}`)
        return
      }
      const stateSession = getSessionById(getState(), account_id, session_id)
      if (stateSession && (stateSession.processing || stateSession.state)) {
        // Session has already been answered
        console.debug(
          'sipsessions',
          'doAnswer',
          'sesssion has already been answered, returning'
        )
        return
      }
      ToneManager.stopAll()
      dispatch({
        type: SIPSESSION_ACCEPT_REQUEST,
        payload: {
          session_id,
          account_id
        }
      })
      sip.answerSession(session_id, config.audio[DEVICE_TYPES.PHONE_INPUT])
    })
  }
}

/**
 * Rejects an inbound session
 *
 * A SIPSESSION_TERMINATED will be called after this as confirmation
 *
 * @function
 * @param account_id {String} - The account ID associated with this session
 * @param session {String} - The session_id as generated
 *
 * @returns {Object} - A promise that will resolve when action was taken
 */
export const doReject = (account_id, session_id) => {
  return dispatch => {
    const sip = AccountManager.getSIP(account_id)
    if (!sip) {
      console.error(`There is no account for id ${account_id}`)
      return
    }
    const session = sip.getSession(session_id)
    if (!session) {
      console.error(`No session ${session_id} for account ${account_id}`)
      return
    }
    dispatch({
      type: SIPSESSION_REJECT_REQUEST,
      payload: {
        session_id,
        account_id
      }
    })
    session.reject()
  }
}

/**
 * Hangs up a established call
 *
 * Beware that this can only be called on sessions that have been previously
 * answered as it will send a SIP BYE message
 *
 * @function
 * @param account_id {String} - The account ID associated with this session
 * @param session {String} - The session_id as generated
 *
 * @returns {Object} - A promise to be resolved when action has been done
 */
export const doHangup = (account_id, session_id) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const sip = AccountManager.getSIP(account_id)
      if (!sip) {
        const err = `There is no account for id ${account_id}`
        console.error(err)
        dispatch({
          type: SIPSESSION_HANGUP_FAIL,
          payload: {
            session_id,
            account_id,
            error: err
          }
        })
        reject(err)
        return
      }
      dispatch({
        type: SIPSESSION_HANGUP_REQUEST,
        payload: {
          session_id,
          account_id
        }
      })
      sip
        .hangupSession(session_id)
        .then(() => {
          dispatch({
            type: SIPSESSION_HANGUP_SUCCESS,
            payload: {
              session_id,
              account_id
            }
          })
          resolve()
        })
        .catch(err => {
          dispatch({
            type: SIPSESSION_HANGUP_FAIL,
            payload: {
              session_id,
              account_id,
              error: err
            }
          })
          reject(err)
        })
    })
  }
}

/**
 * Hold a established call
 *
 * Beware that this can only be called on sessions that have been previously
 * answered
 *
 * @function
 * @param account_id {String} - The account ID associated with this session
 * @param session {String} - The session_id as generated
 *
 * @returns {Object} - A promise to be resolved when action has been done
 */
export const doHold = (account_id, session_id) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const sip = AccountManager.getSIP(account_id)
      dispatch({
        type: SIPSESSION_HOLD_REQUEST,
        payload: {
          session_id,
          account_id
        }
      })
      if (!sip) {
        const err = `There is no account for id ${account_id}`
        console.error(err)
        dispatch({
          type: SIPSESSION_HOLD_FAIL,
          payload: {
            session_id,
            account_id,
            error: err
          }
        })
        resolve(err)
        return
      }
      sip
        .holdSession(session_id)
        .then(() => {
          dispatch({
            type: SIPSESSION_HOLD_SUCCESS,
            payload: {
              session_id,
              account_id
            }
          })
          resolve()
        })
        .catch(err => {
          dispatch({
            type: SIPSESSION_HOLD_FAIL,
            payload: {
              session_id,
              account_id,
              error: err
            }
          })
          reject(err)
        })
    })
  }
}

/**
 * Hold all established calls
 *
 * Beware that this can only be called on sessions that have been previously
 * answered
 *
 * @function
 *
 * @returns {Object} - A promise to be resolved when action has been done
 */
export const doHoldAll = () => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: SIPSESSION_HOLD_ALL_REQUEST,
        payload: {}
      })
      const { sipsessions } = getState()
      if (!sipsessions) {
        dispatch({
          type: SIPSESSION_HOLD_ALL_SUCCESS,
          payload: {}
        })
        resolve()
        return
      }

      const holdPromises = []
      for (let accountId in sipsessions) {
        const { sessions } = sipsessions[accountId] || {}
        if (!sessions) {
          continue
        }

        for (let sessionId in sessions) {
          const stateSession = sessions[sessionId]
          if (!stateSession) {
            continue
          }

          // Session has been answered and is not on hold or requesting hold
          if (
            stateSession.answered &&
            [SIPSESSION_HOLD_REQUEST, SIPSESSION_HOLD_SUCCESS].indexOf(
              stateSession.state
            ) === -1
          ) {
            holdPromises.push(dispatch(doHold(accountId, sessionId)))
          }
        }
      }
      if (!holdPromises.length) {
        dispatch({
          type: SIPSESSION_HOLD_ALL_SUCCESS,
          payload: {}
        })
        resolve()
      } else {
        Promise.all(holdPromises)
          .then(() => {
            dispatch({
              type: SIPSESSION_HOLD_ALL_SUCCESS,
              payload: {}
            })
            resolve()
          })
          .catch(err => {
            dispatch({
              type: SIPSESSION_HOLD_ALL_FAIL,
              payload: {}
            })
            resolve()
          })
      }
    })
  }
}

/**
 * UnHold a held established call
 *
 * Beware that this can only be called on sessions that have been previously
 * answered and held
 *
 * @function
 * @param account_id {String} - The account ID associated with this session
 * @param session {String} - The session_id as generated
 *
 * @returns {Object} - A promise to be resolved when action has been done
 */
export const doUnhold = (account_id, session_id) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: SIPSESSION_UNHOLD_REQUEST,
        payload: {
          session_id,
          account_id
        }
      })
      dispatch(doHoldAll())
        .then(() => {
          const sip = AccountManager.getSIP(account_id)
          if (!sip) {
            const err = `There is no account for id ${account_id}`
            console.error(err)
            dispatch({
              type: SIPSESSION_UNHOLD_FAIL,
              payload: {
                session_id,
                account_id,
                error: err
              }
            })
            reject(err)
            return
          }
          sip.unholdSession(session_id).then(() => {
            dispatch({
              type: SIPSESSION_UNHOLD_SUCCESS,
              payload: {
                session_id,
                account_id
              }
            })
            resolve()
          })
          .catch(err => {
            dispatch({
              type: SIPSESSION_UNHOLD_FAIL,
              payload: {
                session_id,
                account_id,
                error: err
              }
            })
            resolve(err)
          })
        })
        .catch(err => {
          dispatch({
            type: SIPSESSION_UNHOLD_FAIL,
            payload: {
              session_id,
              account_id,
              error: err
            }
          })
          resolve(err)
        })
    })
  }
}

/**
 * Make a new call
 *
 * @function
 * @param account_id {String} - The account ID associated with this session
 * @param uri {String} - The destination to call to
 *
 * @returns {Object} - A promise to be resolved when action has been done
 */
export const doMakeCall = (account_id, uri) => {
  return (dispatch, getState) => {
    if (isAgentElectron(getState())) {
      return call(uri)
    }
    if (!isWebRTCEnabledSelector(getState())) {
      return dispatch(quickcall(uri))
    }
    return new Promise((resolve, reject) => {
      const sip = AccountManager.getSIP(account_id)
      const { config } = getState()
      console.error(config)
      dispatch(doHoldAll())
        .then(() => {
          dispatch({
            type: SIPSESSION_MAKECALL_REQUEST,
            payload: {
              account_id,
              uri
            }
          })
          if (!sip) {
            const err = `There is no account for id ${account_id}`
            dispatch({
              type: SIPSESSION_MAKECALL_FAIL,
              payload: {
                account_id,
                uri,
                error: err
              }
            })
            resolve()
            return
          }
          sip
            .makeCall(
              uri,
              config.audio[DEVICE_TYPES.PHONE_INPUT]
            )
            .then(session_id => {
              dispatch({
                type: SIPSESSION_MAKECALL_SUCCESS,
                payload: {
                  session_id,
                  account_id,
                  uri
                }
              })
              resolve()
            })
            .catch(err => {
              console.error(err)
              dispatch({
                type: SIPSESSION_MAKECALL_FAIL,
                payload: {
                  uri,
                  account_id,
                  error: err
                }
              })
              resolve()
            })
        })
        .catch(err => {
          console.error(err)
          dispatch({
            type: SIPSESSION_MAKECALL_FAIL,
            payload: {
              uri,
              account_id,
              error: err
            }
          })
          resolve()
        })
    })
  }
}

/**
 * Cancel an unanswered outbound call
 *
 * @function
 * @param account_id {String} - The account ID associated with this session
 * @param session_id {String} - The session_id to be cancelled
 *
 * @returns {Object} - A promise to be resolved when action has been done
 */
export const doCancel = (account_id, session_id) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { config } = getState()
      const sip = AccountManager.getSIP(account_id)
      dispatch({
        type: SIPSESSION_CANCEL_REQUEST,
        payload: {
          account_id,
          session_id
        }
      })
      if (!sip) {
        const err = `There is no account for id ${account_id}`
        console.error(err)
        dispatch({
          type: SIPSESSION_CANCEL_FAIL,
          payload: {
            account_id,
            session_id,
            error: err
          }
        })
        reject(err)
        return
      }
      sip
        .cancelSession(session_id)
        .then(() => {
          dispatch({
            type: SIPSESSION_CANCEL_SUCCESS,
            payload: {
              session_id,
              account_id
            }
          })
          ToneManager.stopAll()
          playSound(config.audio[DEVICE_TYPES.PHONE_OUTPUT])
          resolve()
        })
        .catch(err => {
          dispatch({
            type: SIPSESSION_CANCEL_FAIL,
            payload: {
              account_id,
              session_id,
              error: err
            }
          })
          reject(err)
        })
    })
  }
}

/**
 * Send DTMF to a active call
 *
 * @function
 * @param account_id {String} - The account ID associated with this session
 * @param session_id {String} - The session_id to be cancelled
 * @param dtmf {String|Integer} - The DTMF digit to be sent
 * @param dialedNumbers {String} - List of previous digits that were sent
 *
 * @returns {Object} - A promise to be resolved when action has been done
 */
export const doDTMF = (account_id, session_id, dtmf) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const sip = AccountManager.getSIP(account_id)
      dispatch({
        type: SIPSESSION_DTMF_REQUEST,
        payload: {
          account_id,
          session_id,
          dtmf
        }
      })
      if (!sip) {
        const err = `There is no account for id ${account_id}`
        console.error(err)
        dispatch({
          type: SIPSESSION_DTMF_FAIL,
          payload: {
            account_id,
            session_id,
            error: err,
            dtmf
          }
        })
        reject(err)
        return
      }
      sip
        .dtmfSession(session_id, dtmf)
        .then(() => {
          dispatch({
            type: SIPSESSION_DTMF_SUCCESS,
            payload: {
              session_id,
              account_id,
              dtmf
            }
          })
          const { config } = getState()
          ToneManager.dtmf(dtmf, config.audio[DEVICE_TYPES.PHONE_OUTPUT])
          resolve()
        })
        .catch(err => {
          dispatch({
            type: SIPSESSION_DTMF_FAIL,
            payload: {
              account_id,
              session_id,
              dtmf,
              error: err
            }
          })
          reject(err)
        })
    })
  }
}

/**
 * Mark a session as being transferred
 *
 * @function
 * @param account_id {String} - The account ID associated with this session
 * @param session_id {String} - The session_id to be cancelled
 *
 * @returns {Object} - A promise to be resolved when action has been done
 */
export const doMarkTransfer = (account_id, session_id) => dispatch => {
  return new Promise(resolve => {
    dispatch({
      type: SIPSESSION_MARK_TRANSFER,
      payload: {
        account_id,
        session_id
      }
    })
    resolve()
  })
}

/**
 * Cancel a transfer
 *
 * @function
 * @param account_id {String} - The account ID associated with this session
 *
 * @returns {Object} - A promise to be resolved when action has been done
 */
export const doCancelTransfer = account_id => {
  return {
    type: SIPSESSION_CANCEL_TRANSFER,
    payload: {
      account_id
    }
  }
}

/**
 * Do the transfer
 *
 * @function
 * @param account_id {String} - The account ID associated with this session
 * @param session_id {String} - The session_id to be transferred
 * @param number {String|Session} - The number to transfer to
 *
 * @returns {Object} - A promise to be resolved when action has been done
 */
export const doTransfer = (account_id, session_id, number) => dispatch => {
  return new Promise((resolve, reject) => {
    const sip = AccountManager.getSIP(account_id)
    if (!sip) {
      const err = `There is no account for id ${account_id}`
      console.error(err)
      dispatch({
        type: SIPSESSION_TRANSFER_FAIL,
        payload: {
          account_id,
          session_id,
          error: err
        }
      })
      reject(err)
      return
    }
    sip
      .referSession(session_id, number)
      .then(() => {
        dispatch({
          type: SIPSESSION_TRANSFER_SUCCESS,
          payload: {
            session_id,
            account_id
          }
        })
        resolve()
      })
      .catch(err => {
        dispatch({
          type: SIPSESSION_TRANSFER_FAIL,
          payload: {
            account_id,
            session_id,
            error: err
          }
        })
        reject(err)
      })
  })
}

/**
 * Toggle mute
 *
 * @function
 * @param account_id {String} - The account ID associated with this session
 * @param session_id {String} - The session_id to be transferred
 *
 * @returns {Object} - A promise to be resolved when action has been done
 */
export const doToggleMute = (account_id, session_id) => (
  dispatch,
  getState
) => {
  return new Promise((resolve, reject) => {
    const sip = AccountManager.getSIP(account_id)
    if (!sip) {
      const err = `There is no account for id ${account_id}`
      console.error(err)
      dispatch({
        type: SIPSESSION_MUTE_TOGGLE_FAIL,
        payload: {
          account_id,
          session_id,
          error: err
        }
      })
      reject(err)
      return
    }
    const { sipsessions } = getState()
    const myAccountSesssions = sipsessions[account_id]
    if (!myAccountSesssions) {
      const err = `There is no account for id ${account_id}`
      console.error(err)
      dispatch({
        type: SIPSESSION_MUTE_TOGGLE_FAIL,
        payload: {
          account_id,
          session_id,
          error: err
        }
      })
      reject(err)
      return
    }
    const mySession = myAccountSesssions.sessions[session_id]
    if (!mySession) {
      const err = `There is no session for id ${session_id}`
      console.error(err)
      dispatch({
        type: SIPSESSION_MUTE_TOGGLE_FAIL,
        payload: {
          account_id,
          session_id,
          error: err
        }
      })
      reject(err)
      return
    }
    let muted = false
    let action = sip.muteSession
    if (mySession.mute) {
      muted = true
      action = sip.unMuteSession
    }
    action(session_id)
      .then(() => {
        dispatch({
          type: SIPSESSION_MUTE_TOGGLE_SUCCESS,
          payload: {
            session_id,
            account_id,
            mute: !muted
          }
        })
        resolve()
      })
      .catch(err => {
        dispatch({
          type: SIPSESSION_MUTE_TOGGLE_FAIL,
          payload: {
            account_id,
            session_id,
            error: err
          }
        })
        reject(err)
      })
  })
}

export const onIceConnectionOk = (account_id, session_id) => {
  return {
    type: SIPSESSION_ICE_CONNECTION_OK,
    payload: {
      account_id,
      session_id
    }
  }
}

export const onIceConnectionProblem = (account_id, session_id) => {
  return {
    type: SIPSESSION_ICE_CONNECTION_PROBLEM,
    payload: {
      account_id,
      session_id
    }
  }
}
