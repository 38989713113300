import { SIPSESSION_HOLD_SUCCESS } from '../actions/sipsessions'

export function getActiveSessionId (state, accountId) {
  const sessions = state.sipsessions[accountId] || { sessions: {} }
  let session_id
  const session_map = Object.keys(sessions.sessions)
  for (let i = session_map.length; i > 0; i--) {
    let tmp_session_id = session_map[i - 1]
    const session = sessions.sessions[tmp_session_id]
    if (
      session.answered &&
      (!session.state || session.state !== SIPSESSION_HOLD_SUCCESS)
    ) {
      session_id = tmp_session_id
      break
    }
  }
  return session_id
}

export function getSessionById (state, accountId, sessionId) {
  const sessions = (state.sipsessions && state.sipsessions[accountId]) || {
    sessions: {}
  }
  return sessions.sessions[sessionId]
}

export function getSessions (state, accountId) {
  let piece = state.sipsessions[accountId] || { sessions: {} }
  return piece.sessions
}

export function hasAccount(state) {
  return Object.keys(state.sipsessions).length > 0
}
