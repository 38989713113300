import {
  DIRECTORY_LIST_RESPONSE,
  DIRECTORY_UPDATE_PRESENCE,
  DIRECTORY_UPDATE_REGISTRATION,
  DIRECTORY_SET_FILTER
} from '../actions/directories'

const INITIAL_STATE = {
  users: {},
  filter: undefined
}

const directories = (state = INITIAL_STATE, action) => {
  const {
    type,
    payload
  } = action;
  switch(type) {
    case DIRECTORY_UPDATE_REGISTRATION: {
      var user = state.users[payload.user_id]
      if (!user) return state
      if (payload.registered) {
        user.registrations[payload.device_id] = {
          device_id: payload.device_id
        }
      } else {
        delete user.registrations[payload.device_id]
      }
      return {
        ...state,
        users: {
          ...state.users,
          [payload.user_id]: user
        }
      }
    }
    case DIRECTORY_UPDATE_PRESENCE: {
      if (!state.users[payload.user_id]) return state
      return {
        ...state,
        users: {
          ...state.users,
          [payload.user_id]: {
            ...state.users[payload.user_id],
            presence: payload.state
          }
        }
      }
    }
    case DIRECTORY_SET_FILTER: {
      return {
        ...state,
        filter: action.payload
      }
    }
    case DIRECTORY_LIST_RESPONSE: {
      return {
        ...state,
        users: payload.users
      }
    }
    default: {
      return state
    }
  }
}

export default directories
