import {
  AGENT_SET
} from '../actions/agent'

const INITIAL_STATE = {
  name: ''
}

const agent = (state = INITIAL_STATE, action) => {
  const {type, payload} = action
  switch (type) {
    case AGENT_SET: {
      return {
        name: payload.name
      }
    }
    default: {
      return state
    }
  }
}

export default agent
