import { doLogin, doRefresh } from 'ucp/actions/auth'
import { set as agentSet } from '../../actions/agent'
import { AgentTypes } from '../../constants/agents'
import store from 'stores/redux_store.jsx';

export default class DesktopConnectorClient {
    constructor() {
        this.connector = null
        this.history = null
        this.dispatch = null
    }

    initialize(history) {
        if (this.connector) return;
        if (window.webappConnector) {
            this.connector = window.webappConnector
            this.connector.on('message', this.onMessage)
            this.connector.emit('teams-open')
            this.dispatch = store.dispatch;
            this.history = history
            this.dispatch(agentSet(AgentTypes.AGENT_ELECTRON))
        }
    }

    close() {
        this.connector.off('message', this.onMessage)
    }

    call = (extension) => {
        const type = 'call'
        const payload = { extension }
        this.connector.emit('teams-message', { type, payload })
    }

    onMessage = (message) => {
        const { type, payload } = message
        switch (type) {
            case 'teams-login-request': {
                const { email, password, account } = payload
                doLogin(email, password, account)
            }
            case 'teams-refresh-request': {
                const { token } = payload
                this.history.push(`/ucp_token=${token}`)
            }
        }
    }
}
