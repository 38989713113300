import { createSelector } from "reselect"

export const webrtcSelector = state => state.webrtc

export const isWebRTCEnabledSelector = createSelector(
  webrtcSelector,
  state => state.enabled
)

export const deviceSelector = createSelector(
  webrtcSelector,
  isWebRTCEnabledSelector,
  (state, isEnabled) => {
    if (!isEnabled || !state.device) {
      return {
        sip: {}
      }
    } else {
      return state.device
    }
  }
)

export const accountIDSelector = createSelector(
  deviceSelector,
  device => device.sip.username
)
