import axios from 'axios'

import getURL from './env'

export const makeCall = (account_id, user_id, phone_number, token) => {
  return axios.get(`${getURL()}/v2/accounts/${account_id}/users/${user_id}/quickcall/${phone_number}`,
    {
      headers: {
        'X-Auth-Token': token
      }
  })
}
