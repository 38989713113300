import { CALLBOX_SET_NUMBER } from 'ucp/actions/callbox';

const INITIAL_STATE = {
  number: '', 
}

const callbox = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch(type) {
    case CALLBOX_SET_NUMBER: {
      return {
        ...state,
        number: action.payload
      }
    }

    default: {
      return state
    }
  }
}

export default callbox