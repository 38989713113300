// TODO: We need a method to remove accounts
class AccountManager {
  constructor () {
    console.debug('AccountManager', 'constructor')
    this.uas = {}
  }
  addSIP = (id, ua) => {
    console.debug('AccountManager', 'addSIP', id)
    this.uas[id] = ua
  }
  getSIP = (id, ua) => {
    console.debug('AccountManager', 'getSIP', id)
    return this.uas[id]
  }
  delSIP = id => {
    console.debug('AccountManager', 'delSIP', id)
    delete this.uas[id]
  }
}

const am = new AccountManager()

export default am
