/*
 * This reducer only deals with inbound sessions. They are split because it
 * makes testing as well as understing the code logic better.
 */

import {
  SIPSESSION_ACCEPT_REQUEST,
  SIPSESSION_DTMF_SUCCESS,
  SIPSESSION_REJECT_REQUEST,
  SIPSESSION_HANGUP_REQUEST,
  SIPSESSION_HOLD_REQUEST,
  SIPSESSION_HOLD_SUCCESS,
  SIPSESSION_UNHOLD_REQUEST,
  SIPSESSION_UNHOLD_SUCCESS,
  SIPSESSION_ACCEPT
} from '../../actions/sipsessions'

/*
 * state here is really that individual session we're acting on
 */
const inbound = (state, action) => {
  const { payload, type } = action
  switch (type) {
    case SIPSESSION_ACCEPT: {
      return {
        ...state,
        state: type,
        processing: false,
        answered: true,
        answeredTime: payload.startTime
      }
    }
    case SIPSESSION_UNHOLD_SUCCESS:
    case SIPSESSION_HOLD_SUCCESS: {
      return {
        ...state,
        state: type,
        processing: false
      }
    }
    case SIPSESSION_DTMF_SUCCESS: {
      const dialedNumbers = state.dialedNumbers || ''
      return {
        ...state,
        state: type,
        dialedNumbers: `${dialedNumbers}${payload.dtmf}`,
        processing: false
      }
    }
    case SIPSESSION_UNHOLD_REQUEST:
    case SIPSESSION_HOLD_REQUEST:
    case SIPSESSION_HANGUP_REQUEST:
    case SIPSESSION_REJECT_REQUEST:
    case SIPSESSION_ACCEPT_REQUEST: {
      return {
        ...state,
        state: type,
        processing: true
      }
    }
    default:
      return state
  }
}

export default inbound
