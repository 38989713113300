import { get as getAccount } from '../webapi/accounts'

import { getAccountId } from '../stateapi/user'

export const ACCOUNT_FETCH_REQUEST = 'ACCOUNT_FETCH_REQUEST'
export const ACCOUNT_FETCH_RESPONSE = 'ACCOUNT_FETCH_RESPONSE'
export const ACCOUNT_FETCH_FAIL = 'ACCOUNT_FETCH_FAIL'

export const getCurrent = () => (dispatch, getState) => {
  dispatch({
    type: ACCOUNT_FETCH_REQUEST,
    account_id: getAccountId(getState())
  })
  getAccount()
    .then(response => {
      dispatch({
        type: ACCOUNT_FETCH_RESPONSE,
        payload: response.data
      })
    })
    .catch(err => {
      dispatch({
        type: ACCOUNT_FETCH_FAIL,
        error: true,
        payload: {
          error: err,
          account_id: getAccountId(getState())
        }
      })
    })
}
