import TonePlayer, { playDTMF } from './TonePlayer'

/**
 * Manages playing tones either using the WebAudio API or recorded sounds
 */
class ToneManager {
  constructor () {
    if (window.AudioContext || window.webkitAudioContext) {
      this.supportsWebAudio = true
      console.info('Using the WebAudio API to generate sounds')
    } else {
      console.info('WebAudio API is not currently supported')
      this.supportsWebAudio = false
    }
  }
  ringback (deviceId = 'default') {
    if (this.currentTone) {
      this.currentTone.stop()
      this.currentTone = undefined
    }
    if (this.supportsWebAudio) {
      this.currentTone = new TonePlayer('NA', deviceId)
      this.currentTone.play()
    } else {
    }
  }
  ringtone (deviceId = 'default') {
    if (this.currentTone) {
      this.currentTone.stop()
      this.currentTone = undefined
    }
    if (this.supportsWebAudio) {
      this.currentTone = new TonePlayer('NA', deviceId)
      this.currentTone.play()
    } else {
    }
  }
  callWaiting (deviceId = 'default') {
    if (this.currentTone) {
      this.currentTone.stop()
      this.currentTone = undefined
    }
    if (this.supportsWebAudio) {
      this.currentTone = new TonePlayer('NA', deviceId)
      this.currentTone.play()
    } else {
    }
  }
  callFailed (deviceId = 'default') {
    if (this.currentTone) {
      this.currentTone.stop()
      this.currentTone = undefined
    }
    if (this.supportsWebAudio) {
      this.currentTone = new TonePlayer('NA', deviceId)
      this.currentTone.play()
    } else {
    }
  }
  dtmf (key, deviceId = 'default') {
    if (this.supportsWebAudio) {
      playDTMF(key, deviceId)
    }
  }
  stopAll () {
    if (this.currentTone) {
      this.currentTone.stop()
      this.currentTone = undefined
    }
  }
}

const toneManager = new ToneManager()

export default toneManager
