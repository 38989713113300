import {
  ACCOUNT_FETCH_RESPONSE
} from 'ucp/actions/accounts'

const INITIAL_STATE = {
  initialized: false
}

const accounts = (state = INITIAL_STATE, action) => {
  const {
    type,
    payload
  } = action;
  switch(type) {
    case ACCOUNT_FETCH_RESPONSE: {
      return {
        ...state,
        currentAccount: payload,
        initialized: true
      }
    }
    default: {
      return state
    }
  }
}

export default accounts
