import { patch } from "../webapi/callforward"
import { getUserId, getAccountId } from "../stateapi/user"
import { getToken } from "../stateapi/auth"

export const CALL_FORWARD_STATE_CHANGE_REQUEST =
  "CALL_FORWARD_STATE_CHANGE_REQUEST"
export const CALL_FORWARD_STATE_CHANGE_RESPONSE =
  "CALL_FORWARD_STATE_CHANGE_RESPONSE"
export const CALL_FORWARD_STATE_CHANGE_ERROR = "CALL_FORWARD_STATE_CHANGE_ERROR"
export const CALL_FORWARD_NUMBER_CHANGE_REQUEST =
  "CALL_FORWARD_NUMBER_CHANGE_REQUEST"
export const CALL_FORWARD_NUMBER_CHANGE_RESPONSE =
  "CALL_FORWARD_NUMBER_CHANGE_RESPONSE"
export const CALL_FORWARD_NUMBER_CHANGE_ERROR =
  "CALL_FORWARD_NUMBER_CHANGE_ERROR"

export const stateChange = (enabled, failover) => {
  return (dispatch, getState) => {
    dispatch({
      type: CALL_FORWARD_STATE_CHANGE_REQUEST
    })
    patch(
      getAccountId(getState()),
      getUserId(getState()),
      { enabled, failover },
      getToken(getState())
    )
      .then(resp => {
        dispatch({
          type: CALL_FORWARD_STATE_CHANGE_RESPONSE,
          payload: {
            enabled: resp.data.data.call_forward.enabled,
            failover: resp.data.data.call_forward.failover
          }
        })
      })
      .catch(error => {
        dispatch({
          type: CALL_FORWARD_STATE_CHANGE_ERROR,
          error: true,
          payload: error
        })
      })
  }
}

export const changeNumber = number => {
  return (dispatch, getState) => {
    dispatch({
      type: CALL_FORWARD_NUMBER_CHANGE_REQUEST
    })
    patch(
      getAccountId(getState()),
      getUserId(getState()),
      { number },
      getToken(getState())
    )
      .then(resp => {
        dispatch({
          type: CALL_FORWARD_NUMBER_CHANGE_RESPONSE,
          payload: {
            number: resp.data.data.call_forward.number
          }
        })
      })
      .catch(error => {
        dispatch({
          type: CALL_FORWARD_NUMBER_CHANGE_ERROR,
          error: true,
          payload: error
        })
      })
  }
}
