import { DEVICE_TYPES, AUDIO_CHANGE_DEVICE } from '../actions/audio'
import { REHYDRATE } from 'redux-persist/constants'
import { TOGGLE_RINGING } from '../actions/config'

export const INITIAL_STATE = {
  // We leave settings as undefined because not all browsers allows us to know
  // the default device in all situations. The rest of the app is done so that
  // undefined means, I don't know how to select a device kinda deal
  audio: {
    [DEVICE_TYPES.PHONE_INPUT]: undefined,
    [DEVICE_TYPES.PHONE_OUTPUT]: undefined,
    [DEVICE_TYPES.PHONE_RINGING]: undefined,
    [DEVICE_TYPES.AUDIO_SOUNDS]: undefined
  },
  lang: 'en',
  calls: {},
  isRingingActive: true,
  enableExperimentalFeatures: false
}

const config = (state = INITIAL_STATE, action) => {
  const { type, payload } = action
  switch (type) {
    case AUDIO_CHANGE_DEVICE: {
      return {
        ...state,
        audio: {
          ...state.audio,
          [payload.type]: payload.deviceId
        }
      }
    }

    case TOGGLE_RINGING: {
      console.debug('Reducers', 'config', TOGGLE_RINGING)
      return {
        ...state,
        isRingingActive: !state.isRingingActive
      }
    }
    case REHYDRATE: {
      console.debug('Reducers', 'config', REHYDRATE)
      const config = { ...state }
      if (payload.config) {
        // Load AUDIO config:
        if (payload.config.audio) {
          config.audio = payload.config.audio
        } else {
          config.audio = INITIAL_STATE.audio
        }

        if (payload.config.lang) {
          config.lang = payload.config.lang
        } else {
          config.lang = INITIAL_STATE.lang
        }

        // Load isringingActive
        config.isRingingActive =
          typeof payload.config.isRingingActive === 'undefined'
            ? INITIAL_STATE.isRingingActive
            : payload.config.isRingingActive

        // Load enableexperimentalfeatures settings
        config.enableExperimentalFeatures =
          payload.config.enableExperimentalFeatures ||
          INITIAL_STATE.enableExperimentalFeatures
      }
      return config
    }
    default: {
      return state
    }
  }
}

export default config
