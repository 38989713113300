export default () => {
  if (window.config && window.config.backend_url) {
    return window.config.backend_url
  }
  return `${window.location.protocol}//${window.location.hostname}:${process.env.REACT_APP_API_PORT || window.location.port || 443}`
}

export const getWSURL = () => {
  if (window.config && window.config.backend_url) {
    const url = new URL(window.config.backend_url)
    return `${url.protocol === "https:" ? "wss" : "ws"}://${
      url.hostname
      }:${process.env.REACT_APP_API_PORT || url.port || 443}`
  }
  return `${window.location.protocol === "https:" ? "wss" : "ws"}://${
    window.location.hostname
  }:${process.env.REACT_APP_API_PORT || window.location.port || 443}`
}

export const getUCPURL = () => {
  if (window.config && window.config.backend_url) {
    return window.config.backend_url
  }
  if (process.env.NODE_ENV === 'production') {
    return `${window.location.protocol}//${window.location.hostname}:${process.env.REACT_APP_API_PORT || window.location.port || 443}`
  } else {
    return `${window.location.protocol}//${window.location.hostname}:${process.env.REACT_APP_API_PORT || window.location.port || 443}/api`
  }
}
