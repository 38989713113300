import { detect } from 'detect-browser'

import {
  WEBRTC_ENABLE
} from '../actions/webrtc'

const browser = detect()

const INITIAL_STATE = {
  enabled: false
}

const webrtc = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case WEBRTC_ENABLE: {
      if (browser.name !== 'chrome' || process.env.REACT_APP_WEBRTC_DISABLE) { return state }
      return {
        ...state,
        enabled: true,
        device: action.payload
      }
    }
    default: {
      return state
    }
  }
}

export default webrtc
