import {
  AUTH_LOGIN_SUCCESS,
  AUTH_TOKEN_RENEW_FAIL,
  AUTH_LOGIN_REQUEST,
  AUTH_LOGIN_FAIL,
  AUTH_LOGOUT
} from '../actions/auth'

const INITIAL_STATE = {
}

const creds = (state = INITIAL_STATE, action) => {
  const { type, payload } = action
  switch (type) {
    case AUTH_LOGIN_REQUEST: {
      return INITIAL_STATE
    }
    case AUTH_TOKEN_RENEW_FAIL:
    case AUTH_LOGOUT: {
      return INITIAL_STATE
    }
    case AUTH_LOGIN_SUCCESS: {
      return {
        ...state,
        refreshToken: payload.refreshToken
      }
    }
    case AUTH_LOGIN_FAIL: {
      return {
        ...INITIAL_STATE
      }
    }
    default: {
      return state
    }
  }
}

export default creds
