import { patch } from "../webapi/voicemail"
import { getUserId, getAccountId, getUser } from "../stateapi/user"
import { getToken } from "../stateapi/auth"

export const VOICEMAIL_STATE_CHANGE_REQUEST = "VOICEMAIL_STATE_CHANGE_REQUEST"
export const VOICEMAIL_STATE_CHANGE_RESPONSE = "VOICEMAIL_STATE_CHANGE_RESPONSE"
export const VOICEMAIL_STATE_CHANGE_ERROR = "VOICEMAIL_STATE_CHANGE_ERROR"

export const toggle = () => {
  return (dispatch, getState) => {
    dispatch({
      type: VOICEMAIL_STATE_CHANGE_REQUEST
    })
    const user = getUser(getState())
    patch(
      getAccountId(getState()),
      getUserId(getState()),
      !user.hasVoicemail(),
      getToken(getState())
    )
      .then(resp => {
        dispatch({
          type: VOICEMAIL_STATE_CHANGE_RESPONSE,
          payload: resp.data.data.vm_to_email_enabled
        })
      })
      .catch(error => {
        dispatch({
          type: VOICEMAIL_STATE_CHANGE_ERROR,
          error: true,
          payload: error
        })
      })
  }
}
