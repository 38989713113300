/**
 * @module Audio
 *
 * These are all actions that deal with how we play audio as well as WebRTC
 * related audio choices
 */
export const AUDIO_GET_DEVICES_REQUEST = 'AUDIO_GET_DEVICES_REQUEST'
export const AUDIO_GET_DEVICES_SUCCESS = 'AUDIO_GET_DEVICES_SUCCESS'
export const AUDIO_GET_DEVICES_FAIL = 'AUDIO_GET_DEVICES_FAIL'
export const AUDIO_CHANGE_DEVICE = 'AUDIO_CHANGE_DEVICE'

/**
 * The audio types in which we're interested in
 */
export const DEVICE_TYPES = {
  PHONE_INPUT: 'PHONE_INPUT',
  PHONE_OUTPUT: 'PHONE_OUTPUT',
  PHONE_RINGING: 'PHONE_RINGING',
  AUDIO_SOUNDS: 'AUDIO_SOUNDS'
}

/**
 * List devices on the system but does not acquire a stream
 *
 * @function
 * @returns {Promise} - a Promise that resolves to devices
 */
export const listDevices = () => dispatch => {
  return new Promise(resolve => {
    dispatch({
      type: AUDIO_GET_DEVICES_REQUEST
    })

    navigator.mediaDevices
    .enumerateDevices()
    .then(devices => {
      dispatch({
        type: AUDIO_GET_DEVICES_SUCCESS,
        payload: {
          devices: devices.map(device => {
            return {
              id: device.deviceId,
              // Firefox does not provide label if the user does not always
              // allow us to list devices. We don't know what default is
              // either as it doesn't let us know
              label: device.label || device.deviceId,
              kind: device.kind
            }
          })
        }
      })
      resolve(devices)
    })
    .catch(err => {
      console.error('Failed to list devices', err)
      dispatch({
        type: AUDIO_GET_DEVICES_FAIL
      })
      resolve('Failed to list devices')
    })
  })
}

/**
 * Change the device for a specific type
 *
 * @function
 * @param type {String} - the device type we're changing
 * @param deviceId {String} - the new device ID
 */
export const changeDevice = (type, deviceId) => ({
  type: AUDIO_CHANGE_DEVICE,
  payload: {
    type,
    deviceId
  }
})
