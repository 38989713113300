import { hasUser } from './user'

export function isAuthenticated (state) {
  if (!state || !state.auth || !state.auth.token) {
    return false
  }
  return hasUser(state)
}

export function isAuthenticating (state) {
  if (!state || !state.auth || !state.auth.authenticating) {
    return false
  }
  return !isAuthenticated(state) && state.auth.authenticating
}

export function getToken (state) {
  if (isAuthenticated(state) && !isAuthenticating(state)) {
    return state.auth.token
  }
}

export function isWebRTCEnabledByCollaboration(state) {
  if(!state.auth.collaboration) return false
  return state.auth.collaboration.webrtc
}

export function logged(state) {
  return state.auth.loggedIn
}

export function haveCollaboration(state) {
  if (!state.auth.collaboration) return false
  return state.auth.collaboration.enabled
}
