import { makeCall as makeCallAPI } from '../webapi/quickcall'

import { getAccountId, getUserId } from '../stateapi/user'
import { getToken } from '../stateapi/auth'

export const QUICKCALL_REQUEST = 'QUICKCALL_REQUEST'
export const QUICKCALL_RESPONSE = 'QUICKCALL_RESPONSE'
export const QUICKCALL_FAIL = 'QUICKCALL_FAIL'

export const makeCall = (phone_number) => (dispatch, getState) => {
  dispatch({
    type: QUICKCALL_REQUEST
  })
  makeCallAPI(getAccountId(getState()), getUserId(getState()), phone_number, getToken(getState()))
    .then(response => {
      alert('Your phone should be ringing. Answer it in order to complete the call')
      dispatch({
        type: QUICKCALL_RESPONSE,
        payload: {
          devices: response.data.data
        }
      })
    })
    .catch(err => {
      alert('Call failed')
      dispatch({
        type: QUICKCALL_FAIL,
        payload: {
          error: err
        }
      })
    })
}
