import {
  SIPSESSION_ON_INVITE,
  SIPSESSION_TERMINATED,
  SIPSESSION_MAKECALL_SUCCESS,
  SIPSESSION_CANCEL_TRANSFER,
  SIPSESSION_MARK_TRANSFER,
  SIPSESSION_MUTE_TOGGLE_SUCCESS,
  SIPSESSION_CANCEL_SUCCESS,
  SIPSESSION_ICE_CONNECTION_PROBLEM,
  SIPSESSION_ICE_CONNECTION_OK
} from '../../actions/sipsessions'

import { SIPACCOUNT_REGISTERED } from '../../actions/sipaccount'

import inbound from './inbound'
import outbound from './outbound'
import { TOGGLE_INCOMING_CALL_RINGING } from '../../actions/config'

export const SESSION_INBOUND = 'SESSION_INBOUND'
export const SESSION_OUTBOUND = 'SESSION_OUTBOUND'
export const INITIAL_STATE = {}

const sipsessions = (state = INITIAL_STATE, action) => {
  const { type, payload = {} } = action
  const { account_id, session_id } = payload

  switch (type) {
    case SIPACCOUNT_REGISTERED: {
      let sessions = {}
      if (state[payload.id] && state[payload.id].sessions) {
        sessions = state[payload.id].sessions
      }
      return {
        ...state,
        [payload.id]: {
          transferring: null,
          sessions
        }
      }
    }
    case SIPSESSION_MUTE_TOGGLE_SUCCESS: {
      if (
        session_id &&
        state[account_id] &&
        state[account_id].sessions[session_id]
      ) {
        const session = state[account_id].sessions[session_id]
        return {
          ...state,
          [account_id]: {
            ...state[account_id],
            sessions: {
              ...state[account_id].sessions,
              [session_id]: {
                ...session,
                mute: payload.mute
              }
            }
          }
        }
      } else {
        return state
      }
    }
    case TOGGLE_INCOMING_CALL_RINGING: {
      console.debug('Reducers', 'config', TOGGLE_INCOMING_CALL_RINGING)
      return {
        ...state,
        [payload.accountId]: {
          ...state[payload.accountId],
          sessions: {
            ...state[payload.accountId].sessions,
            [payload.sessionId]: {
              ...state[payload.accountId].sessions[payload.sessionId],
              isMuted: !state[payload.accountId].sessions[payload.sessionId].isMuted
            }
          }
        }
      }
    }
    case SIPSESSION_CANCEL_TRANSFER: {
      return {
        ...state,
        [account_id]: {
          ...state[account_id],
          transferring: null
        }
      }
    }
    case SIPSESSION_MARK_TRANSFER: {
      return {
        ...state,
        [account_id]: {
          ...state[account_id],
          transferring: session_id
        }
      }
    }
    case SIPSESSION_ON_INVITE: {
      return {
        ...state,
        [account_id]: {
          ...state[account_id],
          sessions: {
            ...state[account_id].sessions,
            [session_id]: {
              direction: SESSION_INBOUND,
              displayName: payload.displayName,
              user: payload.user,
              // Just flags a session as something is being worked on so users
              // cant simply try and add yet another action on it
              processing: false,
              interaction_id: payload.interaction_id
            }
          }
        }
      }
    }
    case SIPSESSION_MAKECALL_SUCCESS: {
      return {
        ...state,
        [account_id]: {
          ...state[account_id],
          sessions: {
            ...state[account_id].sessions,
            [session_id]: {
              direction: SESSION_OUTBOUND,
              dialedNumber: payload.uri,
              processing: false,
              interaction_id: payload.interaction_id
            }
          }
        }
      }
    }
    // This takes care of removing the session for both inbound and outbound
    // so we really don't care what is the current direction simply because the
    // session is gone anyway
    case SIPSESSION_CANCEL_SUCCESS:
    case SIPSESSION_TERMINATED: {
      // If the session or account are not here, we return the state unchanged
      // but this is technically an error on the application that needs to be
      // treated somewhere else.
      if (!state[account_id] || !state[account_id].sessions[session_id]) {
        console.error(`There is no ${account_id} on the accounts or no
          ${session_id} on the sessions for state ${state}`)
        return state
      }
      let { [session_id]: omit, ...newSessions } = state[account_id].sessions
      let transferring = state[account_id].transferring || null
      if (session_id === state[account_id].transferring) {
        transferring = null
      }
      return {
        ...state,
        [account_id]: {
          ...state[account_id],
          transferring,
          sessions: newSessions
        }
      }
    }
    case SIPSESSION_ICE_CONNECTION_PROBLEM: {
      const accountState = state[account_id] || {}
      const accountSessionsState = accountState.sessions || {}
      const sessionState = accountSessionsState[session_id] || {}
      return {
        ...state,
        [account_id]: {
          ...accountState,
          sessions: {
            ...accountSessionsState,
            [session_id]: {
              ...sessionState,
              connectivityIssues: true
            }
          }
        }
      }
    }
    case SIPSESSION_ICE_CONNECTION_OK: {
      const accountState = state[account_id] || {}
      const accountSessionsState = accountState.sessions || {}
      const sessionState = accountSessionsState[session_id] || {}
      return {
        ...state,
        [account_id]: {
          ...accountState,
          sessions: {
            ...accountSessionsState,
            [session_id]: {
              ...sessionState,
              connectivityIssues: false
            }
          }
        }
      }
    }
    default: {
      // We want to split between inbound vs outbound so that the flow is easier to
      // test as well as to reduce complexity on the individual flow reducers since
      // it does need to seek the account and session id.
      // Beware that we want to do that for all but TERMINATED since that's the
      // event where we need to clear the main reducer keys
      if (
        session_id &&
        state[account_id] &&
        state[account_id].sessions[session_id]
      ) {
        console.debug(
          `Event ${type} is for a pre-existing session ${session_id} on ${account_id}`
        )
        const session = state[account_id].sessions[session_id]
        let newSession
        if (session.direction === SESSION_INBOUND) {
          newSession = inbound(session, action)
        } else {
          newSession = outbound(session, action)
        }
        return {
          ...state,
          [account_id]: {
            ...state[account_id],
            sessions: {
              ...state[account_id].sessions,
              [session_id]: newSession
            }
          }
        }
      } else {
        return state
      }
    }
  }
}

export default sipsessions
