import { isIncomingCallMuted } from '../stateapi/config'
import ToneManager from '../lib/ToneManager'
import { DEVICE_TYPES } from './audio'

export const TOGGLE_RINGING = 'TOGGLE_RINGING'
export const TOGGLE_INCOMING_CALL_RINGING = 'TOGGLE_INCOMING_CALL_RINGING'

export const toggleRinging = (accountId) => ({
  type: TOGGLE_RINGING,
  payload: {
    accountId
  }
})
export const togglIncomingCallRinging = (accountId, sessionId) => {
  return (dispatch, getState) => {
    const state = getState()
    const isMuted = isIncomingCallMuted(state, accountId, sessionId)
    if (isMuted) {
      ToneManager.ringtone(state.config.audio[DEVICE_TYPES.PHONE_RINGING])
    } else {
      ToneManager.stopAll()
    }
    dispatch({
      type: TOGGLE_INCOMING_CALL_RINGING,
      payload: {
        accountId,
        sessionId
      }
    })
  }
}
