import { patch } from '../webapi/callerid'
import { getUserId, getAccountId, getUser } from '../stateapi/user'
import { getCallerIdData } from '../stateapi/callerid'
import { getUserDIDs } from '../stateapi/callflows'
import { getToken } from '../stateapi/auth'

export const CALLER_ID_STATE_CHANGE_REQUEST = 'CALLER_ID_STATE_CHANGE_REQUEST'
export const CALLER_ID_STATE_CHANGE_RESPONSE = 'CALLER_ID_STATE_CHANGE_RESPONSE'
export const CALLER_ID_STATE_CHANGE_ERROR = 'CALLER_ID_STATE_CHANGE_ERROR'
export const CALLER_ID_NUMBER_CHANGE_REQUEST = 'CALLER_ID_NUMBER_CHANGE_REQUEST'
export const CALLER_ID_NUMBER_CHANGE_RESPONSE = 'CALLER_ID_NUMBER_CHANGE_RESPONSE'
export const CALLER_ID_NUMBER_CHANGE_ERROR = 'CALLER_ID_NUMBER_CHANGE_ERROR'

export const toggle = () => {
  return (dispatch, getState) => {
    dispatch({
      type: CALLER_ID_STATE_CHANGE_REQUEST
    })
    const user = getUser(getState())
    let data = {}
    if (user.hasCallerID()) {
      data = {
        ...getCallerIdData(getState()),
        external: {}
      }
    } else {
      data = {
        ...getCallerIdData(getState()),
        external: {
          number: getUserDIDs(getState())[0]
        }
      }
    }
    patch(
      getAccountId(getState()),
      getUserId(getState()),
      data,
      getToken(getState())
    ).then((resp) => {
      dispatch({
        type: CALLER_ID_STATE_CHANGE_RESPONSE,
        payload: resp.data.data.caller_id
      })
    }).catch((error) => {
      dispatch({
        type: CALLER_ID_STATE_CHANGE_ERROR,
        error: true,
        payload: error
      })
    })
  }
}

export const changeNumber = (number) => {
  return (dispatch, getState) => {
    dispatch({
      type: CALLER_ID_NUMBER_CHANGE_REQUEST
    })
    patch(
      getAccountId(getState()),
      getUserId(getState()),
      {
        ...getCallerIdData(getState()),
        external: {
          number
        }
      },
      getToken(getState())
    ).then((resp) => {
      dispatch({
        type: CALLER_ID_NUMBER_CHANGE_RESPONSE,
        payload: resp.data.data.caller_id
      })
    }).catch((error) => {
      dispatch({
        type: CALLER_ID_NUMBER_CHANGE_ERROR,
        error: true,
        payload: error
      })
    })
  }
}
