/**
 * @type User
 *
 * User Model
 */
export default class User {
  constructor (user_data) {
    this._user_data = user_data
  }
  /**
   * Check if user has call forwarding enabled
   */
  hasCallFwd () {
    if (this._user_data.call_forward) {
      return true
    }
    return false
  }
  /**
   * Checks if user has hot desking
   */
  hasHotDesk () {
    if (this._user_data.hotdesk) {
      return this._user_data.hotdesk.enabled
    } else {
      return false
    }
  }
  /**
   * Checks if user has voicemail
   */
  hasVoicemail () {
    return this._user_data.vm_to_email_enabled
  }
  /**
   * Checks if user has caller id
   */
  hasCallerID () {
    if (!!this._user_data.caller_id.external) {
      return !!this._user_data.caller_id.external.number
    }
    return false
  }
  /**
   * Checks if user has conferencing enabled
   */
  hasConferencing () {
    if (this._user_data.smartpbx && this._user_data.smartpbx.conferencing) {
      return this._user_data.smartpbx.conferencing.enabled
    }
    return false
  }
  /**
   * Checks if user has conferencing enabled
   */
  hasFaxing () {
    if (this._user_data.smartpbx && this._user_data.smartpbx.faxing) {
      return this._user_data.smartpbx.faxing.enabled
    }
    return false
  }
  /**
   * Checks if user has customized call recordings
   */
  hasCustomCallRec () {
    if (this._user_data.smartpbx && this._user_data.smartpbx.call_recording) {
      return this._user_data.smartpbx.call_recording.enabled
    }
    return false
  }
  /**
   * Checks if user has find and follow me
   */
  hasFindFollowMe () {
    if (
      this._user_data.smartpbx &&
      this._user_data.smartpbx.find_me_follow_me
    ) {
      return this._user_data.smartpbx.find_me_follow_me.enabled
    }
    return false
  }
  /**
   * Checks if user has MOH set
   */
  hasMOH () {
    if (
      this._user_data.music_on_hold &&
      this._user_data.music_on_hold.media_id
    ) {
      return true
    }
    return false
  }
}
