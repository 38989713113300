import { getCurrentUserMain } from "../stateapi/callflows"
import { get as getFlow, post as saveFlow } from "../webapi/callflows"
import { getAccountId, getUser, getUserId } from "../stateapi/user"
import { getToken } from "../stateapi/auth"
import { patch } from "../webapi/fmfm"

export const FMFM_TOGGLE_REQUEST = "FMFM_TOGGLE_REQUEST"
export const FMFM_TOGGLE_RESPONSE = "FMFM_TOGGLE_RESPONSE"
export const FMFM_TOGGLE_ERROR = "FMFM_TOGGLE_ERROR"

const TOTAL_TIME = 120

export const toggle = () => (dispatch, getState) => {
  dispatch({
    type: FMFM_TOGGLE_REQUEST
  })
  getFlow(
    getAccountId(getState()),
    getCurrentUserMain(getState())._id,
    getToken(getState())
  ).then(response => {
    const doc = response.data.data
    let newData
    const user = getUser(getState())
    if (user.hasFindFollowMe()) {
      // lets disable...
      newData = {
        ...doc,
        flow: {
          ...doc.flow,
          data: {
            can_call_self: false,
            id: getUserId(getState()),
            timeout: 20
          },
          module: "user"
        }
      }
    } else {
      // lets enable....
      const { devices } = getState().devices
      const equal_distro = TOTAL_TIME / devices.length
      newData = {
        ...doc,
        flow: {
          ...doc.flow,
          data: {
            strategy: "simultaneous",
            timeout: TOTAL_TIME,
            endpoints: devices.map((device, index) => ({
              id: device.id,
              endpoint_type: "device",
              delay: equal_distro * index,
              timeout: equal_distro
            }))
          },
          module: "ring_group"
        }
      }
    }
    saveFlow(
      getAccountId(getState()),
      doc.id,
      newData,
      getToken(getState())
    ).then(_ => {
      patch(
        getAccountId(getState()),
        getUserId(getState()),
        !user.hasFindFollowMe(),
        getToken(getState())
      ).then(response => {
        dispatch({
          type: FMFM_TOGGLE_RESPONSE,
          payload: {
            enabled: response.data.data.smartpbx.find_me_follow_me.enabled
          }
        })
      })
    })
  })
}
