import Tone from 'tone'

AudioParam.prototype.cancelAndHoldAtTime = false

/*
const TONE_COUNTRIES = {
  NA: {
    freq: 440,
    playTime: 2000,
    silenceTime: 4000
  },
  EU: {
    freq: 425,
    playTime: 1000,
    silenceTime: 3000
  }
}
*/

const DTMF_MATRIX = {
  1: [697, 1209],
  2: [697, 1336],
  3: [697, 1477],
  A: [697, 1633],
  4: [770, 1209],
  5: [770, 1336],
  6: [770, 1477],
  B: [770, 1633],
  7: [852, 1209],
  8: [852, 1336],
  9: [852, 1477],
  C: [852, 1633],
  0: [941, 1209],
  '*': [941, 1336],
  '#': [941, 1477],
  D: [941, 1633]
}

const FMSynth = Tone.PolySynth && new Tone.PolySynth(2, Tone.FMSynth)
const Synth = Tone.PolySynth && new Tone.PolySynth(2, Tone.Synth)

function NotValidDTMF () {
  this.message = 'Not a valid DTMF'
  this.toString = function () {
    return this.message
  }
}

export const callDisconnect = (id = 'default') => {
  FMSynth.triggerAttack(['C4', 'E4'], '+0.1')
  FMSynth.triggerRelease(['C4', 'E4'], '+0.14')
  FMSynth.triggerAttack(['D4', 'G4'], '+0.14')
  FMSynth.triggerRelease(['D4', 'G4'], '+0.18')
  if (id !== 'default') {
    let audio = new window.Audio()
    let dest = Tone.context.createMediaStreamDestination()
    FMSynth.connect(dest)
    audio.src = window.URL.createObjectURL(dest.stream)
    audio.setSinkId(id)
    audio.play()
  } else {
    FMSynth.toMaster()
  }
}

export const chat = (id = 'default') => {
  var player = new Tone.Player(audio)
  // play as soon as the buffer is loaded
  player.autostart = true
  /* let synth = new Tone.PolySynth(2, Tone.FMSynth)
  synth.triggerAttack(['C4', 'E4'])
  synth.triggerRelease(['C4', 'E4'], '+0.02')
  synth.triggerAttack(['D4', 'G4'], '+0.02')
  synth.triggerRelease(['D4', 'G4'], '+0.04') */
  if (id !== 'default') {
    // let audio = new window.Audio()
    // let dest = Tone.context.createMediaStreamDestination()
    // synth.connect(dest)
    // audio.src = window.URL.createObjectURL(dest.stream)
    player.setSinkId(id).then( _ => {

      player.play()
    })
  } else {
    player.toMaster()
  }
}

export const playDTMF = (key, id = 'default') => {
  let obj = DTMF_MATRIX[key]
  if (!obj) {
    throw new NotValidDTMF()
  }
  Synth.volume.value = -22
  Synth.set({
    oscillator: {
      type: 'sine'
    },
    envelope: {
      attack: 0.02,
      decay: 0.1,
      sustain: 0.2,
      release: 0.02
    }
  })
  if (id !== 'default') {
    let audio = new window.Audio()
    let dest = Tone.context.createMediaStreamDestination()
    Synth.connect(dest)
    audio.src = window.URL.createObjectURL(dest.stream)
    audio.setSinkId(id).then( _ => {
      audio.play()
    })
  } else {
    Synth.toMaster()
  }
  Synth.triggerAttackRelease(obj, 0.3)
}

class TonePlayer {
  constructor (country, deviceId = 'default') {
    this.country = country
    this.deviceId = deviceId
  }
  play = _ => {
    let dest = Tone.context.createMediaStreamDestination()
    Synth
      .set({
        oscillator: {
          type: 'sine'
        },
        envelope: {
          attack: 0.02,
          decay: 0.1,
          sustain: 0.2,
          release: 0.02
        }
      })
      .connect(dest)

    if (this.deviceId !== 'default') {
      let audio = new window.Audio()
      let dest = Tone.context.createMediaStreamDestination()
      Synth.connect(dest)
      audio.src = window.URL.createObjectURL(dest.stream)
      audio.setSinkId(this.deviceId).then( _ => {
        audio.play()
      })
    } else {
      Synth.toMaster()
    }

    this.loop = new Tone.Loop(time => {
      Synth.triggerAttack([440, 480])
      Synth.triggerRelease([440, 480], time + 2)
    }, 6)
    this.loop.start(0)
    Tone.Transport.start()
  }
  stop = _ => {
    this.loop.stop(0)
    Tone.Transport.stop()
    Synth.triggerRelease([440, 480])
  }
}

export default TonePlayer
