import {
  AUTH_LOGIN_SUCCESS,
  AUTH_TOKEN_RENEW_SUCCESS
} from '../actions/auth'
import {
  CALL_FORWARD_STATE_CHANGE_RESPONSE,
  CALL_FORWARD_NUMBER_CHANGE_RESPONSE
} from '../actions/callforward'
import {
  CALLER_ID_NUMBER_CHANGE_RESPONSE,
  CALLER_ID_STATE_CHANGE_RESPONSE,
} from '../actions/callerid'
import {
  VOICEMAIL_STATE_CHANGE_RESPONSE,
} from '../actions/voicemail'
import {
  CALL_RECORDING_STATE_CHANGE_RESPONSE,
} from '../actions/callrecording'
import {
  FMFM_TOGGLE_RESPONSE,
} from '../actions/fmfm'

export const INITIAL_STATE = {}

const user = (state = INITIAL_STATE, action) => {
  const { type, payload } = action

  switch (type) {
    case AUTH_TOKEN_RENEW_SUCCESS:
    case AUTH_LOGIN_SUCCESS: {
      return {
        ...state,
        ...payload
      }
    }
    case VOICEMAIL_STATE_CHANGE_RESPONSE: {
      return {
        ...state,
        vm_to_email_enabled: action.payload
      }
    }
    case CALLER_ID_NUMBER_CHANGE_RESPONSE:
    case CALLER_ID_STATE_CHANGE_RESPONSE: {
      return {
        ...state,
        caller_id: action.payload
      }
    }
    case CALL_RECORDING_STATE_CHANGE_RESPONSE: {
      return {
        ...state,
        smartpbx: {
          ...state.smartpbx,
          call_recording: {
            enabled: payload
          }
        }
      }
    }
    case FMFM_TOGGLE_RESPONSE: {
      return {
        ...state,
        smartpbx: {
          ...state.smartpbx,
          find_me_follow_me: {
            enabled: payload.enabled
          }
        }
      }
    }
    case CALL_FORWARD_STATE_CHANGE_RESPONSE: {
      return {
        ...state,
        call_forward: {
          ...state.call_forward,
          enabled: action.payload.enabled,
          failover: action.payload.failover
        }
      }
    }
    case CALL_FORWARD_NUMBER_CHANGE_RESPONSE: {
      return {
        ...state,
        call_forward: {
          ...state.call_forward,
          number: action.payload.number,
        }
      }
    }
    default:
      return state
  }
}

export default user
