import {
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGIN_REQUEST,
  AUTH_LOGIN_FAIL,
  AUTH_LOGOUT,
  AUTH_TOKEN_RENEW_REQUEST,
  AUTH_TOKEN_RENEW_SUCCESS,
  AUTH_TOKEN_RENEW_FAIL,
  AUTH_CLEAR_CREDENTIALS,
} from '../actions/auth'

const INITIAL_STATE = {
  authenticating: false,
  loggedIn: false,
  error: false
}

const auth = (state = INITIAL_STATE, action) => {
  const { type, payload } = action
  switch (type) {
    case AUTH_TOKEN_RENEW_REQUEST:
    case AUTH_LOGIN_REQUEST: {
      return {
        ...state,
        error: false,
        authenticating: true
      }
    }
    case AUTH_TOKEN_RENEW_SUCCESS:
    case AUTH_LOGIN_SUCCESS: {
      return {
        ...state,
        error: false,
        loggedIn: true,
        token: payload.token,
        collaboration: payload.collaboration,
        authenticating: false,
        webrtc: payload.collaboration.webrtc
      }
    }
    case AUTH_TOKEN_RENEW_FAIL: {
      return {
        ...INITIAL_STATE,
        error: true
      }
    }
    case AUTH_LOGOUT: {
      return INITIAL_STATE
    }
    case AUTH_LOGIN_FAIL: {
      return {
        ...INITIAL_STATE,
        error: true,
        message: payload.status
      }
    }
    case AUTH_CLEAR_CREDENTIALS: {
      return {
        ...state,
        loggedIn: false,
        collaboration: null
      }
    }
    default: {
      return state
    }
  }
}

export default auth
