import { INITIAL_STATE } from '../reducers/user'
import User from './models/User'

/**
 * Returns if a user exists in cache or not
 *
 * @param state {State} - the application state
 *
 * @returns {Bool}
 */
export function hasUser (state) {
  return state.user !== INITIAL_STATE
}

/**
 * Returns the user extension number
 *
 * @param state {State} - the application state
 *
 * @returns {String|undefined}
 */
export function getExtension (state) {
  if (state.user && state.user.caller_id && state.user.caller_id.internal) {
    return state.user.caller_id.internal.number
  }
}

/**
 * Returns the user extension caller id
 *
 * @param state {State} - the application state
 *
 * @returns {String|undefined}
 */
export function getName (state) {
  if (state.user && state.user.caller_id && state.user.caller_id.internal) {
    return state.user.caller_id.internal.name
  }
}

/**
 * Get logged in user full name
 *
 * @param state {State} - the application state
 *
 * @returns {String|undefined}
 */
export function getFullName (state) {
  if (hasUser(state)) {
    return `${state.user.first_name} ${state.user.last_name}`
  }
}

/**
 * Get logged in user email
 *
 * @param state {State} - the application state
 *
 * @returns {String|undefined}
 */
export function getEmail (state) {
  if (hasUser(state)) {
    return state.user.email
  }
}

/**
 * Retrieves the user_id for the currently logged in user
 *
 * @returns {String|undefined}
 */
export function getUserId (state) {
  if (hasUser(state)) {
    return state.user.user_id
  }
}

/**
 * Retrieves the account_id for the currently logged in user
 *
 * @returns {String|undefined}
 */
export function getAccountId (state) {
  if (hasUser(state)) {
    return state.user.account_id
  }
}

/**
 *
 * Get user object
 *
 * @returns {User|undefined}
 */
export function getUser (state) {
  if (hasUser(state)) {
    return new User(state.user)
  }
}
