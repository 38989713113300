import { list as listDirectories } from '../webapi/directories'
import AccountManager from '../lib/AccountManager'

export const DIRECTORY_LIST_REQUEST = 'DIRECTORY_LIST_REQUEST'
export const DIRECTORY_LIST_RESPONSE = 'DIRECTORY_LIST_RESPONSE'
export const DIRECTORY_LIST_FAIL = 'DIRECTORY_LIST_FAIL'
export const DIRECTORY_SET_FILTER = 'DIRECTORY_SET_FILTER'
export const DIRECTORY_UPDATE_REGISTRATION = 'DIRECTORY_UPDATE_REGISTRATION'
export const DIRECTORY_UPDATE_PRESENCE = 'DIRECTORY_UPDATE_PRESENCE'

export const list = _ => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { token } = getState().auth
      const { account_id } = getState().user
      dispatch({
        type: DIRECTORY_LIST_REQUEST
      })
      listDirectories(account_id, token)
        .then(response => {
          dispatch({
            type: DIRECTORY_LIST_RESPONSE,
            payload: {
              users: response.data
            }
          })
          if (getState().webrtc.enabled) {
            for (let user_id in response.data) {
              const { presence_id, id } = response.data[user_id]
              AccountManager.getSIP(getState().webrtc.device.sip.username).dialog(id, presence_id)
            }
          }
        })
        .catch(err => {
          dispatch({
            type: DIRECTORY_LIST_FAIL,
            payload: {
              error: err
            }
          })
        })
    })
  }
}

export const setFilter = (filter) => {
  return {
    type: DIRECTORY_SET_FILTER,
    payload: filter
  }
}

export const listRequest = _ => {
  return {
    type: DIRECTORY_LIST_REQUEST
  }
}

export const listResponse = (directory) => {
  return {
    type: DIRECTORY_LIST_RESPONSE,
    payload: {
      users: directory
    }
  }
}

export const updateRegistration = (item) => {
  return {
    type: DIRECTORY_UPDATE_REGISTRATION,
    payload: item
  }
}

export const updatePresence = (item) => {
  return {
    type: DIRECTORY_UPDATE_PRESENCE,
    payload: item
  }
}