import {
  login,
  refreshToken as doRefreshToken
} from '../../ucp/webapi/auth'

import store from '../../stores/redux_store.jsx';

export const AUTH_LOGIN_REQUEST = 'AUTH_LOGIN_REQUEST'
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS'
export const AUTH_LOGIN_FAIL = 'AUTH_LOGIN_FAIL'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'
export const AUTH_TOKEN_RENEW_REQUEST = 'AUTH_TOKEN_RENEW_REQUEST'
export const AUTH_TOKEN_RENEW_FAIL = 'AUTH_TOKEN_RENEW_FAIL'
export const AUTH_TOKEN_RENEW_SUCCESS = 'AUTH_TOKEN_RENEW_SUCCESS'
export const AUTH_CLEAR_CREDENTIALS = 'AUTH_CLEAR_CREDENTIALS'

export const clearCredentials = () => {
  return (dispatch) => {
    dispatch({
      type: AUTH_CLEAR_CREDENTIALS
    })
  }
}

const _fireLoginActions = (response) => {
  const {
    email,
    first_name,
    last_name,
    vm_to_email_enabled,
    priv_level,
    language,
    hotdesk,
    call_restriction,
    smartpbx,
    call_forward,
    account_name,
    caller_id,
    music_on_hold,
    ringtones,
    collaboration
  } = response.user
  return {
    token: response.access_token,
    refreshToken: response.refresh_token,
    account_id: response.user.pvt_account_id,
    account_name,
    user_id: response.user._id,
    call_forward,
    isAdmin: priv_level === 'admin',
    smartpbx,
    email,
    first_name,
    caller_id,
    last_name,
    vm_to_email_enabled,
    language,
    hotdesk,
    call_restriction,
    music_on_hold,
    ringtones,
    collaboration
  }
}

export const refreshToken = (username, password, realm) => dispatch => {
  dispatch({
    type: AUTH_TOKEN_RENEW_REQUEST,
    payload: {
      username,
      password,
      realm
    }
  })
  return new Promise((resolve, reject) => {
    doRefreshToken(username, password, realm)
      .then(response => {
        dispatch({
          type: AUTH_TOKEN_RENEW_SUCCESS,
          payload: _fireLoginActions(response.data)
        })
        resolve(response)
      })
      .catch(error => {
        dispatch({
          type: AUTH_TOKEN_RENEW_FAIL,
          payload: {
            status: 'Session expired'
          }
        })
        reject(error)
      })
  })
}

export function doLogin (username, password, realm) {
  login(username, password, realm)
    .then(async response => {
      localStorage.setItem('ucp_token', response.data.refresh_token);
      store.dispatch({
          type: AUTH_LOGIN_SUCCESS,
          payload: _fireLoginActions(response.data)
        })
      })
      .catch(error => {
        store.dispatch({
          type: AUTH_LOGIN_FAIL,
          payload: {
            status: 'Wrong username, password or account.'
          }
        })
      })
  return dispatch => {
    store.dispatch({
      type: AUTH_LOGIN_REQUEST,
      payload: {
        username,
        realm,
        password
      }
    })
  }
}

export function doRefresh (token) {
  doRefreshToken(token)
    .then(async response => {
      localStorage.setItem('ucp_token', token);
      store.dispatch({
          type: AUTH_LOGIN_SUCCESS,
          payload: _fireLoginActions(response.data)
        })
      })
      .catch(error => {
        store.dispatch({
          type: AUTH_LOGIN_FAIL,
          payload: {
            status: 'Wrong username, password or account.'
          }
        })
      })
  return dispatch => {
    store.dispatch({
      type: AUTH_LOGIN_REQUEST,
    })
  }
}

export const logout = () => ({
  type: AUTH_LOGOUT
})
